.ag-theme-material.ag-custom {
    --ag-font-family: 'Nunito', 'Helvetica', 'Roboto', 'sans-serif';
    --ag-material-primary-color: #c23052;
    --ag-material-accent-color: #c23052;
}

.ag-custom .muiBtn{
    text-decoration: none;
    font-size: 0.8125rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 4px 10px;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgb(0, 0, 0, 0.12);
}
.ag-custom .muiBtnPrimary{
    background: var(--ag-material-primary-color);
    color: #ffffff;
}
.ag-custom .muiBtnPrimary:hover{
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgb(135, 33, 57);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14%), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.ag-custom .muiBtnSecondary{
    background: #ffffff;
    color: #333333;
}
.ag-custom .muiBtnSecondary:hover{
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(51,51,51,0.05);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14%), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.ag-custom .muiBtnThird{
    background: #333333;
    color: #ffffff;
}
.ag-custom .muiBtnThird:hover{
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(51,51,51,0.85);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14%), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}



