#print-grid{
    display: none;
}

@media print {
   /*@page {
        size: landscape;
    }*/
    .MuiDrawer-root,
    header.MuiAppBar-root.MuiAppBar-positionFixed,
    .MuiButton-root.MuiButtonBase-root.MuiButton-contained,
    .MuiButton-root.MuiButtonBase-root.MuiButton-outlined,
    .MuiButton-root.MuiButtonBase-root.MuiButton-flip{
        display: none;
    }
    main.MuiBox-root{
        margin-top: 0;
    }
    .MuiPaper-root .MuiGrid-root .MuiTypography-root {
        font-size: 10pt;
    }
    .MuiGrid-root.MuiGrid-item .MuiPaper-root{
        margin-bottom: 0;
    }
    #print-none-grid{
        display: none;
    }
    #print-grid{
        display: flex;
    }
}